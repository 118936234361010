import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Ambitious, subject to change, incomplete, based on `}<a parentName="p" {...{
        "href": "https://dcurt.is/bucket-list"
      }}>{`this`}</a>{`.`}</p>
    <ol>
      <li parentName="ol"><del parentName="li">{`Create something that touches 10,000 people.`}</del>{` (January 20th, 2013)`}</li>
      <li parentName="ol"><del parentName="li">{`Create something that touches 100,000 people.`}</del>{` (January 14th, 2015)`}</li>
      <li parentName="ol">{`Create something that touches 1,000,000 people.`}</li>
      <li parentName="ol"><del parentName="li">{`Receive a standing ovation.`}</del>{` (May 19th, 2010)`}</li>
      <li parentName="ol">{`Deliver a college commencement speech.`}</li>
      <li parentName="ol">{`Deliver a TED talk.`}</li>
      <li parentName="ol">{`Be fluent in two languages.`}</li>
      <li parentName="ol">{`Be fluent in three languages.`}</li>
      <li parentName="ol">{`Skydive.`}</li>
      <li parentName="ol">{`Be involved in one transaction of over $100,000.`}</li>
      <li parentName="ol">{`Make $1,000,000 in a year.`}</li>
      <li parentName="ol">{`Run a marathon.`}</li>
      <li parentName="ol">{`Give $100,000 to charity.`}</li>
      <li parentName="ol"><del parentName="li">{`Wine tasting in Napa.`}</del>{` (July 16th, 2016)`}</li>
      <li parentName="ol">{`Wine tasting in the South of France.`}</li>
      <li parentName="ol"><del parentName="li">{`Speak/perform in front of 10,000 people.`}</del>{` (September 3rd, 2011)`}</li>
      <li parentName="ol">{`Speak/perform in front of 100,000 people.`}</li>
      <li parentName="ol"><del parentName="li">{`Visit 3 continents.`}</del>{` (May 1st, 2014)`}</li>
      <li parentName="ol"><del parentName="li">{`Visit 4 continents.`}</del>{` (November 20th, 2016)`}</li>
      <li parentName="ol"><del parentName="li">{`Visit 5 continents.`}</del>{` (July 25th, 2019)`}</li>
      <li parentName="ol">{`See the sunrise for 7 consecutive days.`}</li>
      <li parentName="ol"><del parentName="li">{`Contribute to an open source project with more than 100 contributors.`}</del>{` (November 22nd, 2016)`}</li>
      <li parentName="ol"><del parentName="li">{`Contribute to an open source project with more than 1,000 commits.`}</del>{` (January 8th, 2015)`}</li>
      <li parentName="ol">{`Contribute to an open source project with more than 10,000 commits.`}</li>
      <li parentName="ol">{`Give a keynote address.`}</li>
      <li parentName="ol">{`Give a guest lecture.`}</li>
      <li parentName="ol">{`Have a news article written about me.`}</li>
      <li parentName="ol">{`Be on the cover of a magazine.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      